import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NewsCard from '../components/newsCard';

const NewsFeed = () => {
  const [news, setNews] = useState([]); // News articles
  const [query, setQuery] = useState(''); // User's search keyword
  const [loading, setLoading] = useState(false); // Loading indicator
  const [error, setError] = useState(null); // Error message

  // Fetch news based on default industries or user search
  const fetchNews = async (searchQuery) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get('https://gnews.io/api/v4/search', {
        params: {
          token: '00da9ad60e7e0abffc282645a61583ad', // Replace with your actual GNews API key
          lang: 'en',
          q: searchQuery, // Query can be default or user-provided
          max: 10,
        },
      });

      // Process response articles
      const articles = response.data.articles.map((article) => ({
        title: article.title,
        description: article.description,
        url: article.url,
        source: article.source.name,
        image: article.image,
      }));

      setNews(articles); // Update news state
    } catch (err) {
      setError('Failed to fetch news. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch for default industries
  useEffect(() => {
    fetchNews('construction OR engineering OR hydropower');
  }, []);

  // Handle user search submission
  const handleSearch = (e) => {
    e.preventDefault();
    if (!query.trim()) {
      setError('Please enter a valid search term.');
      return;
    }
    fetchNews(query); // Fetch news based on user's query
  };

  return (
    <div>
      <h1>News Feed</h1>

      {/* Search Form */}
      <form onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Search news (e.g., construction, engineering)"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          style={{ padding: '10px', width: '300px' }}
        />
        <button type="submit" style={{ padding: '10px 20px', marginLeft: '10px' }}>
          Search
        </button>
      </form>

      {/* Loading and Error Messages */}
      {loading && <p>Loading news...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* News Display */}
      <div>
        {news.length === 0 && !loading && !error && <p>No news found. Try another search term!</p>}
        {news.map((article, index) => (
          <NewsCard key={index} {...article} />
        ))}
      </div>
    </div>
  );
};

export default NewsFeed;
