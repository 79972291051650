import React from 'react';

const NewsCard = ({ title, description, url, source, image }) => (
  <div style={{ border: '1px solid #ddd', padding: '10px', margin: '10px', borderRadius: '8px' }}>
    {image && <img src={image} alt={title} style={{ width: '100%', height: '200px', objectFit: 'cover' }} />}
    <h3>{title}</h3>
    <p>{description}</p>
    <p><strong>Source:</strong> {source}</p>
    <a href={url} target="_blank" rel="noopener noreferrer">
      Read more
    </a>
  </div>
);


export default NewsCard;
